import React, { useCallback, useEffect, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { useGetBlockchainsQuery } from '../../../data/actions/getBlockchains';
import { useLazyGetIncidentsQuery } from '../../../data/actions/getIncidents';
import { formatStatsData } from '../../../data/utils';

export type TRequestPeriodId = string;

export type TRequestPeriodUnit = 'days' | 'hours';

export type TRequestPeriod = {
  id: TRequestPeriodId;
  value: number;
  unit: TRequestPeriodUnit;
  requestArgument: 'd' | 'h';
};

export const REQUEST_PERIOD_DEFAULT: TRequestPeriod = {
  id: '14d',
  value: 14,
  unit: 'days',
  requestArgument: 'd',
};

export const PERIOD_OPTIONS: TRequestPeriod[] = [
  { id: '12h', value: 12, unit: 'hours', requestArgument: 'h' },
  { id: '24h', value: 24, unit: 'hours', requestArgument: 'h' },
  { id: '7d', value: 7, unit: 'days', requestArgument: 'd' },
  REQUEST_PERIOD_DEFAULT,
  { id: '30d', value: 30, unit: 'days', requestArgument: 'd' },
];

export const useDashboard = () => {
  const [requestPeriod, setRequestPeriod] = React.useState<TRequestPeriod>(
    REQUEST_PERIOD_DEFAULT,
  );

  const { data: blockchains = [], isLoading: isLoadingBlockchains } =
    useGetBlockchainsQuery();

  const [
    requestData,
    {
      data: rawStats = [],
      error: errorStats,
      isLoading: isLoadingStats,
      isFetching: isFetchingStats,
    },
  ] = useLazyGetIncidentsQuery();

  useEffect(() => {
    if (!isLoadingBlockchains && blockchains?.length > 0) {
      requestData({
        period: REQUEST_PERIOD_DEFAULT.id,
        blockchains,
      })
        .then(data => data)
        .catch(error => console.error(error));
    }
  }, [requestData, blockchains, isLoadingBlockchains]);

  const onChangeRequestPeriod = useCallback(
    async (event: SelectChangeEvent<TRequestPeriodId>) => {
      const newRequestPeriod = PERIOD_OPTIONS.find(
        option => option.id === event.target.value,
      );

      if (!newRequestPeriod) return;

      setRequestPeriod(newRequestPeriod);

      if (blockchains) {
        await requestData({
          period: newRequestPeriod.id,
          blockchains,
        });
      }
    },
    [requestData, setRequestPeriod, blockchains],
  );

  const dataStats = useMemo(
    () =>
      formatStatsData(
        blockchains,
        rawStats,
        requestPeriod.value,
        requestPeriod.unit,
      ),
    [blockchains, rawStats, requestPeriod],
  );

  return {
    requestPeriod,
    isLoadingBlockchains,
    dataStats,
    errorStats,
    isLoadingStats,
    isFetchingStats,
    onChangeRequestPeriod,
  };
};
